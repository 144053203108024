import axios from "axios";
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const apiServerUrl = config.public.apiServerUrl;

  const api = axios.create({
    baseURL: `${apiServerUrl}/api/v1/`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });

  // api.interceptors.request.use(config => {
  //   if (accessToken) {
  //     config.headers.Authorization = `Bearer ${accessToken}`;
  //   }
  //   return config;
  // });

  return {
    provide: {
      api: api,
    },
  };
});
