import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {

  const config = useRuntimeConfig();

  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: config.public.grecaptcha.siteKey,
    loaderOptions: {
      autoHideBadge: config.public.grecaptcha.hideBadge,
      explicitRenderParameters: {
        badge: 'bottomleft',
      },
    },
  });
});
