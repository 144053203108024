import { defineNuxtPlugin } from '#app/nuxt'
import { LazyButtonsButton, LazyButtonsPageLinkButton, LazyCard, LazyCarousel, LazyCategories, LazyComments, LazyDateAdded, LazyEstimatedReadingTime, LazyFieldsFilePreview, LazyFieldsFileUploadField, LazyFieldsTagFollowersField, LazyFormSuccessMessage, LazyIconsArrowDownIcon, LazyIconsArrowLeftIcon, LazyIconsArrowRightIcon, LazyIconsArrowUpIcon, LazyIconsBellIcon, LazyIconsBinIcon, LazyIconsBrandGraphic, LazyIconsCalendarIcon, LazyIconsClockIcon, LazyIconsConfusedGraphic, LazyIconsCountdownTimerIcon, LazyIconsDocumentIcon, LazyIconsEnvelopeIcon, LazyIconsErrorIcon, LazyIconsEyeHidden, LazyIconsEyeVisibile, LazyIconsFacebookIcon, LazyIconsFolderIcon, LazyIconsHomeIcon, LazyIconsInstagramIcon, LazyIconsListIcon, LazyIconsLocationPin, LazyIconsLockIcon, LazyIconsLogoutIcon, LazyIconsMegaphoneIcon, LazyIconsPencilIcon, LazyIconsPlusIcon, LazyIconsSearchIcon, LazyIconsShareIcon, LazyIconsSirenIcon, LazyIconsSliderIcon, LazyIconsSpeechBubble, LazyIconsTickInCircleIcon, LazyIconsTimelineIcon, LazyIconsTodoIcon, LazyIconsUserIcon, LazyIconsXIcon, LazyIllustrationsAirplaneBaloonGraphic, LazyIllustrationsBannerGraphic, LazyIllustrationsBrainstormingGraphic, LazyIllustrationsCatAndDogGraphic, LazyIllustrationsFamilyHoldingBabyGraphic, LazyIllustrationsGraduatingFromUniGraphic, LazyIllustrationsHouseWithCarParkedGraphic, LazyIllustrationsPeopleShakingHands, LazyIllustrationsPersonClimbingMountain, LazyIllustrationsPersonInHospitalGraphic, LazyIllustrationsPersonRidingBikeGraphic, LazyIllustrationsPhoneGraphic, LazyIllustrationsWalletGraphic, LazyLikes, LazyListItemsListItem, LazyListItems, LazyLoaderSpinner, LazyLogo, LazyModal, LazyNavigationHamburger, LazyNavigationMobileNavigation, LazyNewsletterSignupForm, LazyPagination, LazyPanelsPanel, LazySearch, LazySearchTagSearch, LazyShare, LazyTag } from '#components'
const lazyGlobalComponents = [
  ["ButtonsButton", LazyButtonsButton],
["ButtonsPageLinkButton", LazyButtonsPageLinkButton],
["Card", LazyCard],
["Carousel", LazyCarousel],
["Categories", LazyCategories],
["Comments", LazyComments],
["DateAdded", LazyDateAdded],
["EstimatedReadingTime", LazyEstimatedReadingTime],
["FieldsFilePreview", LazyFieldsFilePreview],
["FieldsFileUploadField", LazyFieldsFileUploadField],
["FieldsTagFollowersField", LazyFieldsTagFollowersField],
["FormSuccessMessage", LazyFormSuccessMessage],
["IconsArrowDownIcon", LazyIconsArrowDownIcon],
["IconsArrowLeftIcon", LazyIconsArrowLeftIcon],
["IconsArrowRightIcon", LazyIconsArrowRightIcon],
["IconsArrowUpIcon", LazyIconsArrowUpIcon],
["IconsBellIcon", LazyIconsBellIcon],
["IconsBinIcon", LazyIconsBinIcon],
["IconsBrandGraphic", LazyIconsBrandGraphic],
["IconsCalendarIcon", LazyIconsCalendarIcon],
["IconsClockIcon", LazyIconsClockIcon],
["IconsConfusedGraphic", LazyIconsConfusedGraphic],
["IconsCountdownTimerIcon", LazyIconsCountdownTimerIcon],
["IconsDocumentIcon", LazyIconsDocumentIcon],
["IconsEnvelopeIcon", LazyIconsEnvelopeIcon],
["IconsErrorIcon", LazyIconsErrorIcon],
["IconsEyeHidden", LazyIconsEyeHidden],
["IconsEyeVisibile", LazyIconsEyeVisibile],
["IconsFacebookIcon", LazyIconsFacebookIcon],
["IconsFolderIcon", LazyIconsFolderIcon],
["IconsHomeIcon", LazyIconsHomeIcon],
["IconsInstagramIcon", LazyIconsInstagramIcon],
["IconsListIcon", LazyIconsListIcon],
["IconsLocationPin", LazyIconsLocationPin],
["IconsLockIcon", LazyIconsLockIcon],
["IconsLogoutIcon", LazyIconsLogoutIcon],
["IconsMegaphoneIcon", LazyIconsMegaphoneIcon],
["IconsPencilIcon", LazyIconsPencilIcon],
["IconsPlusIcon", LazyIconsPlusIcon],
["IconsSearchIcon", LazyIconsSearchIcon],
["IconsShareIcon", LazyIconsShareIcon],
["IconsSirenIcon", LazyIconsSirenIcon],
["IconsSliderIcon", LazyIconsSliderIcon],
["IconsSpeechBubble", LazyIconsSpeechBubble],
["IconsTickInCircleIcon", LazyIconsTickInCircleIcon],
["IconsTimelineIcon", LazyIconsTimelineIcon],
["IconsTodoIcon", LazyIconsTodoIcon],
["IconsUserIcon", LazyIconsUserIcon],
["IconsXIcon", LazyIconsXIcon],
["IllustrationsAirplaneBaloonGraphic", LazyIllustrationsAirplaneBaloonGraphic],
["IllustrationsBannerGraphic", LazyIllustrationsBannerGraphic],
["IllustrationsBrainstormingGraphic", LazyIllustrationsBrainstormingGraphic],
["IllustrationsCatAndDogGraphic", LazyIllustrationsCatAndDogGraphic],
["IllustrationsFamilyHoldingBabyGraphic", LazyIllustrationsFamilyHoldingBabyGraphic],
["IllustrationsGraduatingFromUniGraphic", LazyIllustrationsGraduatingFromUniGraphic],
["IllustrationsHouseWithCarParkedGraphic", LazyIllustrationsHouseWithCarParkedGraphic],
["IllustrationsPeopleShakingHands", LazyIllustrationsPeopleShakingHands],
["IllustrationsPersonClimbingMountain", LazyIllustrationsPersonClimbingMountain],
["IllustrationsPersonInHospitalGraphic", LazyIllustrationsPersonInHospitalGraphic],
["IllustrationsPersonRidingBikeGraphic", LazyIllustrationsPersonRidingBikeGraphic],
["IllustrationsPhoneGraphic", LazyIllustrationsPhoneGraphic],
["IllustrationsWalletGraphic", LazyIllustrationsWalletGraphic],
["Likes", LazyLikes],
["ListItemsListItem", LazyListItemsListItem],
["ListItems", LazyListItems],
["LoaderSpinner", LazyLoaderSpinner],
["Logo", LazyLogo],
["Modal", LazyModal],
["NavigationHamburger", LazyNavigationHamburger],
["NavigationMobileNavigation", LazyNavigationMobileNavigation],
["NewsletterSignupForm", LazyNewsletterSignupForm],
["Pagination", LazyPagination],
["PanelsPanel", LazyPanelsPanel],
["Search", LazySearch],
["SearchTagSearch", LazySearchTagSearch],
["Share", LazyShare],
["Tag", LazyTag],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
