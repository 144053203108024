import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin, useRouter } from 'nuxt/app'
export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  const { public: { sentry, apiServerUrl} } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,  // Capture 100% of the transactions
    tracePropagationTargets: ["localhost", apiServerUrl],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
});
