interface CookieYesConsent {
  [key: string]: string;
}

export default function getCookie(key: string): string | undefined {
  const cookies: { [key: string]: string } = document.cookie
    .split(";")
    .reduce((ac, cv) => {
      const [cookieKey, cookieValue] = cv.split("=").map((c) => c.trim());
      return { ...ac, [cookieKey]: cookieValue };
    }, {});

  const consentCookies: CookieYesConsent = cookies["cookieyes-consent"]
    ? cookies["cookieyes-consent"]
      .split(",")
      .reduce((obj, pair) => {
        const [pairKey, pairValue] = pair.split(":").map((p) => p.trim());
        return { ...obj, [pairKey]: pairValue };
      }, {})
    : {};

  return consentCookies[key];
}
