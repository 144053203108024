import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Fdvg3o8568 from "/vercel/path0/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_eTVJQYlCmx from "/vercel/path0/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth0_client_xw5Ytmgnib from "/vercel/path0/plugins/auth0.client.ts";
import axios_QMFgzss1s4 from "/vercel/path0/plugins/axios.ts";
import gtag_client_Zw8EQXNVTz from "/vercel/path0/plugins/gtag.client.ts";
import recaptcha_85gNSCNFUU from "/vercel/path0/plugins/recaptcha.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import vue_final_modal_pML93k5qcp from "/vercel/path0/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_Fdvg3o8568,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  auth0_client_xw5Ytmgnib,
  axios_QMFgzss1s4,
  gtag_client_Zw8EQXNVTz,
  recaptcha_85gNSCNFUU,
  sentry_3AyO8nEfhE,
  vue_final_modal_pML93k5qcp
]