import VueGtag, { trackRouter } from 'vue-gtag-next'
import getCookie from "~/utils/cookiesyes";

export default defineNuxtPlugin((nuxtApp) => {

  const config = useRuntimeConfig();

  const isEnabled = getCookie("consent") == "yes" && getCookie("analytics") == "yes";

  nuxtApp.vueApp.use(VueGtag, {
    property: {
      id: config.public.gtagId,
      params: {
        anonymize_ip: true,
      }
    },
    isEnabled: isEnabled,
  })
  trackRouter(useRouter())
})
