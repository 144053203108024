import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as account_45onboardinglvNZrYU5c3Meta } from "/vercel/path0/pages/account-onboarding.vue?macro=true";
import { default as dashboardtI6nX61aVPMeta } from "/vercel/path0/pages/account/dashboard.vue?macro=true";
import { default as edit_45profileXrbWTlOOIzMeta } from "/vercel/path0/pages/account/edit-profile.vue?macro=true";
import { default as _91id_93d0ZlUyd1v6Meta } from "/vercel/path0/pages/account/emergency-planning/[id].vue?macro=true";
import { default as add_45new6diPSmgKTaMeta } from "/vercel/path0/pages/account/emergency-planning/add-new.vue?macro=true";
import { default as indexBw7R341u3LMeta } from "/vercel/path0/pages/account/emergency-planning/index.vue?macro=true";
import { default as followerszDZn901dbqMeta } from "/vercel/path0/pages/account/followers.vue?macro=true";
import { default as _91id_93zjrcfTQTe1Meta } from "/vercel/path0/pages/account/future-plans/[id].vue?macro=true";
import { default as add_45newH9tyl98W0zMeta } from "/vercel/path0/pages/account/future-plans/add-new.vue?macro=true";
import { default as indexGpSal63ed2Meta } from "/vercel/path0/pages/account/future-plans/index.vue?macro=true";
import { default as logout1svPCTP3GDMeta } from "/vercel/path0/pages/account/logout.vue?macro=true";
import { default as my_45accountuD5mYFN1deMeta } from "/vercel/path0/pages/account/my-account.vue?macro=true";
import { default as _91id_93R6iNgrSJyeMeta } from "/vercel/path0/pages/account/notes-to-self/[id].vue?macro=true";
import { default as add_45newaX3UpzLjUlMeta } from "/vercel/path0/pages/account/notes-to-self/add-new.vue?macro=true";
import { default as indexnaxAbcjI6PMeta } from "/vercel/path0/pages/account/notes-to-self/index.vue?macro=true";
import { default as notificationsFuA6KiWVEwMeta } from "/vercel/path0/pages/account/notifications.vue?macro=true";
import { default as _91id_93nplLNz0bbhMeta } from "/vercel/path0/pages/account/past-events/[id].vue?macro=true";
import { default as add_45newJuxR5GkSziMeta } from "/vercel/path0/pages/account/past-events/add-new.vue?macro=true";
import { default as index30DusCQn5gMeta } from "/vercel/path0/pages/account/past-events/index.vue?macro=true";
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as callbackb5WsT7qIxWMeta } from "/vercel/path0/pages/callback.vue?macro=true";
import { default as delegate_45accessuIzd52gwGjMeta } from "/vercel/path0/pages/delegate-access.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91slug_93FyMToRXETCMeta } from "/vercel/path0/pages/resources/[[slug]]/[slug].vue?macro=true";
import { default as indexS1Y0mOtI4KMeta } from "/vercel/path0/pages/resources/index.vue?macro=true";
import { default as _91id_93SzBwZfUAB5Meta } from "/vercel/path0/pages/timeline/[id].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: account_45onboardinglvNZrYU5c3Meta?.name ?? "account-onboarding",
    path: account_45onboardinglvNZrYU5c3Meta?.path ?? "/account-onboarding",
    meta: account_45onboardinglvNZrYU5c3Meta || {},
    alias: account_45onboardinglvNZrYU5c3Meta?.alias || [],
    redirect: account_45onboardinglvNZrYU5c3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account-onboarding.vue").then(m => m.default || m)
  },
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account",
    path: accountLUTTRGMhg3Meta?.path ?? "/account",
    children: [
  {
    name: dashboardtI6nX61aVPMeta?.name ?? "account-dashboard",
    path: dashboardtI6nX61aVPMeta?.path ?? "dashboard",
    meta: dashboardtI6nX61aVPMeta || {},
    alias: dashboardtI6nX61aVPMeta?.alias || [],
    redirect: dashboardtI6nX61aVPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileXrbWTlOOIzMeta?.name ?? "account-edit-profile",
    path: edit_45profileXrbWTlOOIzMeta?.path ?? "edit-profile",
    meta: edit_45profileXrbWTlOOIzMeta || {},
    alias: edit_45profileXrbWTlOOIzMeta?.alias || [],
    redirect: edit_45profileXrbWTlOOIzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: _91id_93d0ZlUyd1v6Meta?.name ?? "account-emergency-planning-id",
    path: _91id_93d0ZlUyd1v6Meta?.path ?? "emergency-planning/:id()",
    meta: _91id_93d0ZlUyd1v6Meta || {},
    alias: _91id_93d0ZlUyd1v6Meta?.alias || [],
    redirect: _91id_93d0ZlUyd1v6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/emergency-planning/[id].vue").then(m => m.default || m)
  },
  {
    name: add_45new6diPSmgKTaMeta?.name ?? "account-emergency-planning-add-new",
    path: add_45new6diPSmgKTaMeta?.path ?? "emergency-planning/add-new",
    meta: add_45new6diPSmgKTaMeta || {},
    alias: add_45new6diPSmgKTaMeta?.alias || [],
    redirect: add_45new6diPSmgKTaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/emergency-planning/add-new.vue").then(m => m.default || m)
  },
  {
    name: indexBw7R341u3LMeta?.name ?? "account-emergency-planning",
    path: indexBw7R341u3LMeta?.path ?? "emergency-planning",
    meta: indexBw7R341u3LMeta || {},
    alias: indexBw7R341u3LMeta?.alias || [],
    redirect: indexBw7R341u3LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/emergency-planning/index.vue").then(m => m.default || m)
  },
  {
    name: followerszDZn901dbqMeta?.name ?? "account-followers",
    path: followerszDZn901dbqMeta?.path ?? "followers",
    meta: followerszDZn901dbqMeta || {},
    alias: followerszDZn901dbqMeta?.alias || [],
    redirect: followerszDZn901dbqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/followers.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zjrcfTQTe1Meta?.name ?? "account-future-plans-id",
    path: _91id_93zjrcfTQTe1Meta?.path ?? "future-plans/:id()",
    meta: _91id_93zjrcfTQTe1Meta || {},
    alias: _91id_93zjrcfTQTe1Meta?.alias || [],
    redirect: _91id_93zjrcfTQTe1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/future-plans/[id].vue").then(m => m.default || m)
  },
  {
    name: add_45newH9tyl98W0zMeta?.name ?? "account-future-plans-add-new",
    path: add_45newH9tyl98W0zMeta?.path ?? "future-plans/add-new",
    meta: add_45newH9tyl98W0zMeta || {},
    alias: add_45newH9tyl98W0zMeta?.alias || [],
    redirect: add_45newH9tyl98W0zMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/future-plans/add-new.vue").then(m => m.default || m)
  },
  {
    name: indexGpSal63ed2Meta?.name ?? "account-future-plans",
    path: indexGpSal63ed2Meta?.path ?? "future-plans",
    meta: indexGpSal63ed2Meta || {},
    alias: indexGpSal63ed2Meta?.alias || [],
    redirect: indexGpSal63ed2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/future-plans/index.vue").then(m => m.default || m)
  },
  {
    name: logout1svPCTP3GDMeta?.name ?? "account-logout",
    path: logout1svPCTP3GDMeta?.path ?? "logout",
    meta: logout1svPCTP3GDMeta || {},
    alias: logout1svPCTP3GDMeta?.alias || [],
    redirect: logout1svPCTP3GDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/logout.vue").then(m => m.default || m)
  },
  {
    name: my_45accountuD5mYFN1deMeta?.name ?? "account-my-account",
    path: my_45accountuD5mYFN1deMeta?.path ?? "my-account",
    meta: my_45accountuD5mYFN1deMeta || {},
    alias: my_45accountuD5mYFN1deMeta?.alias || [],
    redirect: my_45accountuD5mYFN1deMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/my-account.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R6iNgrSJyeMeta?.name ?? "account-notes-to-self-id",
    path: _91id_93R6iNgrSJyeMeta?.path ?? "notes-to-self/:id()",
    meta: _91id_93R6iNgrSJyeMeta || {},
    alias: _91id_93R6iNgrSJyeMeta?.alias || [],
    redirect: _91id_93R6iNgrSJyeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/notes-to-self/[id].vue").then(m => m.default || m)
  },
  {
    name: add_45newaX3UpzLjUlMeta?.name ?? "account-notes-to-self-add-new",
    path: add_45newaX3UpzLjUlMeta?.path ?? "notes-to-self/add-new",
    meta: add_45newaX3UpzLjUlMeta || {},
    alias: add_45newaX3UpzLjUlMeta?.alias || [],
    redirect: add_45newaX3UpzLjUlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/notes-to-self/add-new.vue").then(m => m.default || m)
  },
  {
    name: indexnaxAbcjI6PMeta?.name ?? "account-notes-to-self",
    path: indexnaxAbcjI6PMeta?.path ?? "notes-to-self",
    meta: indexnaxAbcjI6PMeta || {},
    alias: indexnaxAbcjI6PMeta?.alias || [],
    redirect: indexnaxAbcjI6PMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/notes-to-self/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsFuA6KiWVEwMeta?.name ?? "account-notifications",
    path: notificationsFuA6KiWVEwMeta?.path ?? "notifications",
    meta: notificationsFuA6KiWVEwMeta || {},
    alias: notificationsFuA6KiWVEwMeta?.alias || [],
    redirect: notificationsFuA6KiWVEwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nplLNz0bbhMeta?.name ?? "account-past-events-id",
    path: _91id_93nplLNz0bbhMeta?.path ?? "past-events/:id()",
    meta: _91id_93nplLNz0bbhMeta || {},
    alias: _91id_93nplLNz0bbhMeta?.alias || [],
    redirect: _91id_93nplLNz0bbhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/past-events/[id].vue").then(m => m.default || m)
  },
  {
    name: add_45newJuxR5GkSziMeta?.name ?? "account-past-events-add-new",
    path: add_45newJuxR5GkSziMeta?.path ?? "past-events/add-new",
    meta: add_45newJuxR5GkSziMeta || {},
    alias: add_45newJuxR5GkSziMeta?.alias || [],
    redirect: add_45newJuxR5GkSziMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/past-events/add-new.vue").then(m => m.default || m)
  },
  {
    name: index30DusCQn5gMeta?.name ?? "account-past-events",
    path: index30DusCQn5gMeta?.path ?? "past-events",
    meta: index30DusCQn5gMeta || {},
    alias: index30DusCQn5gMeta?.alias || [],
    redirect: index30DusCQn5gMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/past-events/index.vue").then(m => m.default || m)
  }
],
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: callbackb5WsT7qIxWMeta?.name ?? "callback",
    path: callbackb5WsT7qIxWMeta?.path ?? "/callback",
    meta: callbackb5WsT7qIxWMeta || {},
    alias: callbackb5WsT7qIxWMeta?.alias || [],
    redirect: callbackb5WsT7qIxWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: delegate_45accessuIzd52gwGjMeta?.name ?? "delegate-access",
    path: delegate_45accessuIzd52gwGjMeta?.path ?? "/delegate-access",
    meta: delegate_45accessuIzd52gwGjMeta || {},
    alias: delegate_45accessuIzd52gwGjMeta?.alias || [],
    redirect: delegate_45accessuIzd52gwGjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/delegate-access.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FyMToRXETCMeta?.name ?? "resources-slug-slug",
    path: _91slug_93FyMToRXETCMeta?.path ?? "/resources/:slug?/:slug()",
    meta: _91slug_93FyMToRXETCMeta || {},
    alias: _91slug_93FyMToRXETCMeta?.alias || [],
    redirect: _91slug_93FyMToRXETCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/resources/[[slug]]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexS1Y0mOtI4KMeta?.name ?? "resources",
    path: indexS1Y0mOtI4KMeta?.path ?? "/resources",
    meta: indexS1Y0mOtI4KMeta || {},
    alias: indexS1Y0mOtI4KMeta?.alias || [],
    redirect: indexS1Y0mOtI4KMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SzBwZfUAB5Meta?.name ?? "timeline-id",
    path: _91id_93SzBwZfUAB5Meta?.path ?? "/timeline/:id()",
    meta: _91id_93SzBwZfUAB5Meta || {},
    alias: _91id_93SzBwZfUAB5Meta?.alias || [],
    redirect: _91id_93SzBwZfUAB5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/timeline/[id].vue").then(m => m.default || m)
  }
]